import {
	AutomatitCarousel
} from './automatit_carousel.m';

export const unitFilters = (function(){
	let my = {},
		size = null;

	function _filterUnits(){
		let noUnits = document.getElementById('no_units');
		noUnits.style.display = "block";
		[].forEach.call(document.querySelectorAll('#unit_table .unit_row'), (el) => {
			if(size === null){
				el.style.display = 'table-row';
				noUnits.style.display = "none";
			}else{
				let props = el.dataset.prop.split(' ');
				el.style.display = 'none';
				if(props.includes(size)){
					el.style.display = 'table-row';
					noUnits.style.display = "none";
				}
			}
		});
	}

	function _toggleFilters({currentTarget}){
		if(currentTarget.dataset.filter === "all"){
			[].forEach.call(document.querySelectorAll('#facility_filters .filter'), (el) => {
				el.classList.remove("active");
			});
			size = null;
			currentTarget.classList.add('active');
		}else{
			let active = currentTarget.parentElement.querySelector('.active');
			active.classList.remove('active');
			currentTarget.classList.add('active');
			size = currentTarget.dataset.filter;
		}

		_filterUnits();
	}

	function _assignListeners(){
		[].forEach.call(document.querySelectorAll('#facility_filters .filter'), (el) => {
			el.addEventListener('click', _toggleFilters);
		});
	}

	my.init = () => {
		if(document.getElementById('facility_size_filters')){
			_assignListeners();
		}
	};

	return my;
})();

export const facilityCaro = (function () {
	let my = {};

	my.init = function () {
		if (document.getElementById('facility_caro')) {

			if (window.slide_images.length > 1) {
				const options = {
					element: document.getElementById('facility_caro'),
					speed: 600,
					images: window.slide_images,
					intervalTiming: 6000,
					imagesAsBackgrounds: true,
					showDots: true,
					useCheverons: false
				};
				AutomatitCarousel(options);
			} else {
				let btns = document.getElementById('facility_caro_chevrons');
				btns.parentNode.removeChild(btns);
				const options = {
					element: document.getElementById('facility_caro'),
					speed: 600,
					images: window.slide_images,
					imagesAsBackgrounds: true,
					intervalTiming: 0,
					showDots: false
				};
				AutomatitCarousel(options);
			}
		}
	};

	return my;
})();
