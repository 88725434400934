import { contactSubmit } from './contact.js';
import { facilityCaro, unitFilters } from './facility.js';
import { navToggle } from './header.js';
import { maps } from './map.js';
import { reviewsCaro } from './reviews.js';

navToggle.init();

if(document.getElementById('contact_map')) {
	maps.init();
}

facilityCaro.init();

unitFilters.init();

reviewsCaro.init();

if (document.getElementById('contact_form')) {
	contactSubmit();
}
